import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import View from '@vkontakte/vkui/dist/components/View/View';
import ScreenSpinner from '@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner';
import '@vkontakte/vkui/dist/vkui.css';
import Axios from 'axios';
import Avatar from '@vkontakte/vkui/dist/components/Avatar/Avatar';
import Snackbar from '@vkontakte/vkui/dist/components/Snackbar/Snackbar';
import Icon24CheckCircleOn from '@vkontakte/icons/dist/24/check_circle_on';
import Icon24DismissSubstract from '@vkontakte/icons/dist/24/dismiss_substract';
import { platform, IOS, ModalCard } from '@vkontakte/vkui';

import Home from './panels/Home';
import Create from './panels/Create';
import Room from './panels/Room';
import Profile from './panels/Profile';
import { ModalRoot } from '@vkontakte/vkui/dist/es6';
import ModalPageHeader from '@vkontakte/vkui/dist/components/ModalPageHeader/ModalPageHeader';
import FormLayout from '@vkontakte/vkui/dist/components/FormLayout/FormLayout';
import Input from '@vkontakte/vkui/dist/components/Input/Input';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Icon24Gift from '@vkontakte/icons/dist/es6/24/gift';

const ROUTES = {
	HOME: 'home',
	CREATE: 'create',
	ROOM: 'room',
	PROFILE: 'profile'
};

const SECRETDAR_API_ADDRESS = 'https://secretdar.com';
const osName = platform();

const App = () => {
	const [accessToken, setAccessToken] = useState(null);
	const [activePanel, setActivePanel] = useState(ROUTES.HOME);
	const [fetchedUser, setUser] = useState(null);
	const [externalUser, setExternalUser] = useState(null);
	const [rooms, setRooms] = useState(null);
	const [popout, setPopout] = useState(<ScreenSpinner size='large' />);
	const [room, setRoom] = useState(null);
	const [snackbar, setSnackbar] = useState(null);
	const [activeModal, setActiveModal] = useState(null);
	const [trackData, setTrackData] = useState({trackCode: null, deliveryService: null});

	useEffect(() => {
		async function fetchData() {
			const user = await bridge.send('VKWebAppGetUserInfo');

			let authData = await callApi('/api/validate/' + window.location.search);

			if (!authData.accessToken) {
				alert('Мамкин хакер детектед');
				return;
			}

			setAccessToken(authData.accessToken);

			let params = {
				vkId: user.id,
				firstName: user.first_name,
				lastName: user.last_name,
				avatar: user.photo_200,
				gender: user.sex,
				accessToken: authData.accessToken
			};
			const externalUserData = await callApi('/api/register-mini-app-client/', params);

			let extUser = externalUserData.user;
			extUser.fullName = extUser.firstName + ' ' + extUser.lastName;

			setExternalUser(extUser);
			setRooms(externalUserData.rooms);
			setUser(externalUserData.user);
			setPopout(null);

			if (window.location.hash) {
				let urlParts = window.location.hash.substr(1).split('=');

				if (urlParts.length === 2 && urlParts[0] === 'roomId') {
					let roomId = urlParts[1];

					openRoom(roomId, authData.accessToken);
				}
			}
		}
		fetchData();
	}, []);

	const dateFromString = function(dateString) {
		let date = [];
		date = dateString.split('.').map((i) => {return parseInt(i);});

		return new Date(date[2], date[1] - 1, date[0]);
	}

	const callApi = async function(method, params) {
		if (typeof params !== 'object') {
			params = {};
		}

		setPopout(<ScreenSpinner size='large' />);

		if (accessToken) {
			params['accessToken'] = accessToken;
		}

		for (let i in params) {
			if (typeof params[i] === 'boolean') {
				params[i] = Number(params[i]);
			}
		}

		let response = await Axios.post(SECRETDAR_API_ADDRESS + method, params);
		let data = response.data;

		if (data.message) {
			if (osName === IOS) {
				bridge.send('VKWebAppTapticNotificationOccurred', {type: 'success'}).catch(() => {});
			}

			setSnackbar(<Snackbar
				layout='vertical'
				onClose={() => setSnackbar(null)}
				duration={900}
				before={<Avatar size={24}><Icon24CheckCircleOn fill="#3bb838" /></Avatar>}
			>
				{data.message}
			</Snackbar>);
		}

		if (data.error) {
			if (osName === IOS) {
				bridge.send('VKWebAppTapticNotificationOccurred', {type: 'error'}).catch(() => {});
			}

			setSnackbar(<Snackbar
				layout='vertical'
				onClose={() => setSnackbar(null)}
				before={<Avatar size={24}><Icon24DismissSubstract fill="#ff4153" /></Avatar>}
			>
				{data.error}
			</Snackbar>);
		}

		setPopout(null);

		return data;
	};

	const go = e => {
		setActivePanel(e.currentTarget.dataset.to);
	};

	const openRoom = (roomId, accessToken) => {
		setPopout(<ScreenSpinner size='large' />);

		let params = {};

		if (typeof accessToken !== 'undefined') {
			params.accessToken = accessToken;
		}

		callApi('/api/room/' + roomId + '/', params).then((res) => {
			setRoom(res.room);

			if (res.room.recipient) {
				let trackData = {
					trackCode: res.room.recipient.trackCode,
					deliveryService: res.room.recipient.deliveryService
				};

				setTrackData(trackData);
			}

			setActivePanel(ROUTES.ROOM);
		})
	};

	const saveRoom = (room) => {
		let method;

		if (!room.id) {
			method = '/api/room/create/';
		} else {
			method = '/api/edit/' + room.id + '/';
		}

		callApi(method, room).then((res) => {
			let newRooms = rooms;
			let roomSendDate = dateFromString(res.room.sendDate);
			let now = new Date();

			if (roomSendDate >= now) {
				newRooms.active[newRooms.active.length] = res.room;
			} else {
				newRooms.inactive[newRooms.inactive.length] = res.room;
			}

			setRooms(newRooms);
			openRoom(res.room.id);
		});
	};

	const onTrackChange = (e, field) => {
		e.preventDefault();
		const { value } = e.currentTarget;

		let localTrackData = trackData;

		localTrackData[field] = value;
		setTrackData(localTrackData);
	};

	const saveTrackData = () => {
		let params = {
			trackCode: trackData.trackCode,
			deliveryService: trackData.deliveryService,
			roomId: room.id
		};

		callApi('/api/gift/send/', params).then(() => {
			setActiveModal(null);
		});
	};

	const modal = (<ModalRoot activeModal={activeModal} onClose={() => {setActiveModal(null)}}>
		<ModalCard
			id="sendGift"
			onClose={() => {setActiveModal(null)}}
			header={
				<ModalPageHeader>Отправить</ModalPageHeader>
			}
		>
			<FormLayout>
				<Input
					top="Трек-номер"
					type="text"
					name="trackCode"
					defaultValue={trackData.trackCode}
					onChange={(e) => onTrackChange(e, 'trackCode')}
				/>
				<Input
					top="Доставщик"
					type="text"
					name="deliveryService"
					placeholder="Например: Почта России"
					defaultValue={trackData.deliveryService}
					onChange={(e) => onTrackChange(e, 'deliveryService')}
				/>
				<Button before={<Icon24Gift/>} size="xl" onClick={() => saveTrackData()}>Отправить</Button>
			</FormLayout>
		</ModalCard>
	</ModalRoot>);

	return (
		<View activePanel={activePanel} popout={popout} modal={modal}>
			<Home id={ROUTES.HOME} fetchedUser={fetchedUser} go={go} rooms={rooms} openRoom={openRoom} snackbar={snackbar} />
			<Create id={ROUTES.CREATE} go={go} saveRoom={saveRoom} snackbar={snackbar} />
			<Room id={ROUTES.ROOM} go={go} room={room} setRoom={setRoom} callApi={callApi} externalUser={externalUser} snackbar={snackbar} dateFromString={dateFromString} bridge={bridge} setActiveModal={setActiveModal} setPopuot={setPopout} rooms={rooms} setRooms={setRooms} />
			<Profile id={ROUTES.PROFILE} go={go} fetchedUser={fetchedUser} callApi={callApi} snackbar={snackbar} setUser={setUser} />
		</View>
	);
}

export default App;

