import React, { useState } from 'react';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import PanelHeaderButton from '@vkontakte/vkui/dist/components/PanelHeaderButton/PanelHeaderButton';
import { platform, IOS, FixedLayout, Cell } from '@vkontakte/vkui';
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';
import FormLayout from '@vkontakte/vkui/dist/components/FormLayout/FormLayout';
import Input from '@vkontakte/vkui/dist/components/Input/Input';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Switch from '@vkontakte/vkui/dist/components/Switch/Switch';

const Create = ({ id, go, saveRoom, room, snackbar }) => {
  const [state, setState] = useState(room);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const validate = (room) => {
    let now = new Date();
    now.setDate(now.getDate() + 1);
    let dateViolated = !room || !room.sendDate || (now >= Date.parse(room.sendDate));
    let value = !(room && room.name.length >= 3) || dateViolated;

    setButtonDisabled(value);
  };

  if (!state) {
    setState({
      id: null,
      name: '',
      sendDate: '',
      isPersonal: '',
      isPublic: ''
    });
  }

  const onChange = (e, field) => {
    e.preventDefault();
    const { value } = e.currentTarget;

    setButtonDisabled(false);

    let room = state;

    room[field] = value;
    setState(room);
    validate(state);
  };

  const onCheckboxChange = (e, field) => {
    let room = state;

    room[field] = !!e.currentTarget.checked;
    setState(room);
  };

  return (
    <Panel id={id}>
      <PanelHeader left={<PanelHeaderButton onClick={go} data-to="home">
        {platform() === IOS ? <Icon28ChevronBack/> : <Icon24Back/>}
      </PanelHeaderButton>}>
        {state && state.id ? state.name : 'Новый проект'}
      </PanelHeader>

      <FormLayout>
        <Input
          top="Название"
          placeholder="Например: Новый год"
          type="text"
          name="name"
          defaultValue={state && state.name}
          onChange={(e) => onChange(e, 'name')}
          bottom="Название должно состоять минимум из трех символов (гусары, молчать!)"
        />
        <Input
          top="Дата окончания приема заявок"
          type="date"
          name="sendDate"
          defaultValue={state && state.sendDate}
          onChange={(e) => onChange(e, 'sendDate')}
          bottom="Прием заявок будет закрыт около полудня (Мск) в указанную дату"
        />

        <Group>
          <Cell
            size="l"
            asideContent={<Switch defaultChecked={state && state.isPersonal} onChange={(e) => onCheckboxChange(e, 'isPersonal')}/>}
            description="Подтверждение получения подарка будет отключено"
          >
            Личная встреча
          </Cell>
          <Cell
            size="l"
            asideContent={<Switch defaultChecked={state && state.isPublic} onChange={(e) => onCheckboxChange(e, 'isPublic')}/>}
            description="Не требуется подтверждение организатора"
          >
            Публичный проект
          </Cell>
        </Group>
      </FormLayout>

      <FixedLayout vertical="bottom">
        <Div>
          <Button size="xl" level="2" onClick={(e) => saveRoom(state)} disabled={buttonDisabled}>{state && state.id ? 'Сохранить' : 'Создать'}</Button>
        </Div>
      </FixedLayout>
      {snackbar}
    </Panel>
  );
};

export default Create;