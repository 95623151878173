import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { platform, IOS } from '@vkontakte/vkui';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import PanelHeaderButton from '@vkontakte/vkui/dist/components/PanelHeaderButton/PanelHeaderButton';
import Cell from '@vkontakte/vkui/dist/components/Cell/Cell';
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
import Avatar from '@vkontakte/vkui/dist/components/Avatar/Avatar';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Header from '@vkontakte/vkui/dist/components/Header/Header';
import FixedLayout from '@vkontakte/vkui/dist/components/FixedLayout/FixedLayout';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Icon24Share from '@vkontakte/icons/dist/24/share';
import Icon12Cancel from '@vkontakte/icons/dist/12/cancel';
import Icon24Gift from '@vkontakte/icons/dist/24/gift';
import Icon24Done from '@vkontakte/icons/dist/24/done';
import { Alert, PullToRefresh } from '@vkontakte/vkui/dist/es6';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const osName = platform();

const Room = props => {
  const [isGiftReceived, setIsGiftReceived] = useState(false);
  const [fetching, setFetching] = useState(false);

  let isParticipant = false;

  async function approveUser(userId, roomId, status) {
    let params = {
      userId: userId,
      roomId: roomId,
      status: status,
    };

    props.callApi('/api/room/approve/', params);

    let from;
    let to;
    if (status === 1) {
      from = props.room.unapprovedParticipants;
      to = props.room.participants;
    } else {
      from = props.room.participants;
      to = props.room.unapprovedParticipants;
    }

    for (let i in from) {
      if (from[i].id === userId) {
        to[to.length] = from[i];
        from.splice(i, 1);
        break;
      }
    }
  }

  async function participate(roomId) {
    let params = {
      roomId: roomId,
    };

    props.callApi('/api/room/participate/', params);

    if (!isParticipant) {
      if (typeof props.room.unapprovedParticipants === 'undefined') {
        props.room.unapprovedParticipants = [];
      }

      if (props.room.isPersonal) {
        props.room.unapprovedParticipants[props.room.unapprovedParticipants.length] = props.externalUser;
      } else {
        props.room.participants[props.room.participants.length] = props.externalUser
      }

      props.rooms.active[props.rooms.active.length] = props.room;
      props.setRooms(props.rooms);
    } else {
      for (let i in props.room.unapprovedParticipants) {
        if (props.room.unapprovedParticipants[i].id === props.externalUser.id) {
          props.room.unapprovedParticipants.splice(i, 1);
        }
      }
      for (let i in props.room.participants) {
        if (props.room.participants[i].id === props.externalUser.id) {
          props.room.participants.splice(i, 1);
        }
      }
    }

    isParticipant = !isParticipant;
  }

  async function receiveGift() {
    let params = {
      roomId: props.room.id,
    };

    await props.callApi('/api/gift/receive/', params);

    setIsGiftReceived(true);

    props.setPopuot(null);
  }

  async function refresh() {
    setFetching(true);
    props.callApi('/api/room/' + props.room.id + '/').then((res) => {
      props.setRoom(res.room);
      setFetching(false);
    }).catch(() => {
      setFetching(false);
    });
  }

  function share(e) {
    props.bridge.send('VKWebAppShare', { link: `https://vk.com/app7646754#roomId=${props.room.id}` });
  }

  function openGiftReceiveDialog() {
    props.setPopuot(
      <Alert
        actions={[{
          title: 'Отмена',
          mode: 'cancel',
          action: () => props.setPopuot(null),
        }, {
          title: 'Подтвердить',
          action: () => receiveGift(),
        }]}
      >
        <h2>Подтвердите действие</h2>
        <p>Вы собираетесь подтвердить получение подарка</p>
      </Alert>,
    );
  }

  const renderText = (text) => {
    return text.replace(/(https?:\/\/[\S]+)/g, '<a href="$1" target="_blank">$1</a>');
  };

  let isRoomInactive = props.dateFromString(props.room.sendDate) <= new Date();

  let participants = props.room.participants.map((item, key) => {
    let desc = null;
    let button = null;

    if (props.externalUser.id === item.id) {
      isParticipant = true;
    }

    if (item.id === props.room.authorId) {
      desc = 'Организатор';
    } else if (item.id === props.externalUser.id) {
      button = (
        <div style={{ display: 'flex' }}>
          <Button size="m" onClick={(e) => {participate(props.room.id);}} mode="destructive">Отменить заявку</Button>
          <Button style={{ marginLeft: '8px' }} size="m" onClick={props.go} data-to="profile" mode="secondary">Профиль</Button>
        </div>
      );
    } else if (props.externalUser.id === props.room.authorId && !isRoomInactive) {
      button = (
        <div style={{ display: 'flex' }}><Button before={<Icon12Cancel/>} size="s" mode="secondary" onClick={() => {approveUser(item.id, props.room.id, 0);}}>Отменить</Button>
        </div>);
    }

    return (
      <Cell
        before={<Avatar src={item.avatar}/>}
        description={desc}
        size="l"
        bottomContent={button}
      >
        {item.fullName}
      </Cell>
    );
  });

  let unapprovedParticipants = null;
  if (props.room.unapprovedParticipants && !isRoomInactive && props.room.unapprovedParticipants.length > 0) {
    unapprovedParticipants = props.room.unapprovedParticipants.map((item, key) => {
      let bottomContent;

      if (props.externalUser.id === props.room.authorId) {
        bottomContent = (
          <div style={{ display: 'flex' }}>
            <Button size="m" onClick={() => {approveUser(item.id, props.room.id, 1);}}>Подтвердить</Button>
            {/*<Button size="m" mode="secondary" style={{ marginLeft: 8 }}>Отклонить</Button>*/}
          </div>
        );
      } else if (props.externalUser.id === item.id) {
        isParticipant = true;
        bottomContent = (
          <div style={{ display: 'flex' }}>
            <Button size="m" onClick={(e) => {participate(props.room.id);}} mode="destructive">Отменить заявку</Button>
            <Button style={{ marginLeft: '8px' }} size="m" onClick={props.go} data-to="profile" mode="secondary">Профиль</Button>
          </div>
        );
      }

      return (
        <Cell
          before={<Avatar src={item.avatar}/>}
          size="l"
          bottomContent={bottomContent}
        >
          {item.fullName}
        </Cell>
      );
    });
  }

  let recipient = null;
  if (props.room.recipient) {
    let address = 'Хорошего вам настроения!';
    let giftButtons = null;

    if (!props.room.isPersonal) {
      giftButtons = (<div>
        <Button size="m" before={<Icon24Gift/>} onClick={(e) => props.setActiveModal('sendGift')} mode={props.room.recipient.trackCode ? 'secondary' : 'primary'}>Отправить</Button>
        <Button size="m" before={<Icon24Done/>} style={{ marginLeft: '8px' }} mode="commerce" onClick={(e) => {openGiftReceiveDialog();}}
                disabled={props.room.gift && props.room.gift.isReceived || isGiftReceived}>Получить</Button>
      </div>);
      address = 'Адрес: ' + props.room.recipient.address;

      if (props.room.recipient.isReceived) {
        address += ', подарок получен';
      }
    }

    let gift = null;
    if (props.room.gift) {
      gift = (<div style={{ color: 'var(--text_secondary)', fontSize: '13px', fontWeight: '400' }}>
        <div style={{ fontWeight: '600' }}>Ниже информация по подарку для вас:</div>
        <div>Доставляет {props.room.gift.deliveryService}</div>
        <div>Трек-код: {props.room.gift.trackCode}</div>
      </div>);
    }

    let about = null;
    if (props.room.recipient.about) {
      about = props.room.recipient.about;
      about = renderText(about);
    }

    recipient = (
      <Group>
        <Header>Ваш получатель</Header>
        <Cell
          before={<Avatar size={64} src={props.room.recipient.avatar}/>}
          size="l"
          bottomContent={giftButtons}
        >
          {props.room.recipient.fullName}
          <div style={{ color: 'var(--text_secondary)', fontSize: '13px', fontWeight: '400', whiteSpace: 'normal' }}>
            <div>{address}</div>
            {about && <div>Обо мне: {ReactHtmlParser(about)}</div>}
          </div>
          {gift}
        </Cell>
      </Group>
    );
  }

  return (

    <Panel id={props.id}>
      <PanelHeader
        left={<PanelHeaderButton onClick={props.go} data-to="home">
          {osName === IOS ? <Icon28ChevronBack/> : <Icon24Back/>}
        </PanelHeaderButton>}
      >
        {props.room.name}
      </PanelHeader>

      {recipient}

      <PullToRefresh onRefresh={refresh} isFetching={fetching}>
        <Group>
          <Header>Участники</Header>
          {participants}
        </Group>
        {unapprovedParticipants && <Group>
          <Header>На рассмотрении</Header>
          {unapprovedParticipants}
        </Group>}

        <Div>
          <div style={{ height: '60px' }}></div>
        </Div>


      <FixedLayout vertical="bottom">
        <Div style={{ display: 'flex' }}>
          {!isParticipant && (props.externalUser.id !== props.room.authorId) && <Button
            size="xl"
            onClick={(e) => participate(props.room.id)}
            mode="commerce"
            style={{ marginRight: '8px' }}
          >
            Участвовать
          </Button>
          }
          <Button size="xl" onClick={share} before={<Icon24Share/>} stretched>Поделиться</Button>
        </Div>
      </FixedLayout>

      </PullToRefresh>

      {props.snackbar}
    </Panel>

  );
};

Room.propTypes = {
  id: PropTypes.string.isRequired,
  go: PropTypes.func.isRequired,
  callApi: PropTypes.func.isRequired,
};

export default Room;
