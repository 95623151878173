import React from 'react';
import bridge from '@vkontakte/vk-bridge';
import PropTypes from 'prop-types';
import { platform, IOS, FixedLayout } from '@vkontakte/vkui';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import PanelHeaderButton from '@vkontakte/vkui/dist/components/PanelHeaderButton/PanelHeaderButton';
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
import FormLayout from '@vkontakte/vkui/dist/components/FormLayout/FormLayout';
import Input from '@vkontakte/vkui/dist/components/Input/Input';
import Textarea from '@vkontakte/vkui/dist/components/Textarea/Textarea';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import FormStatus from '@vkontakte/vkui/dist/components/FormStatus/FormStatus';
import FormLayoutGroup from '@vkontakte/vkui/dist/components/FormLayoutGroup/FormLayoutGroup';
import Switch from '@vkontakte/vkui/dist/components/Switch/Switch';
import Cell from '@vkontakte/vkui/dist/components/Cell/Cell';
import Select from '@vkontakte/vkui/dist/components/Select/Select';

const osName = platform();

const Profile = props => {
  let user = Object.assign({}, props.fetchedUser);

  const saveUser = function(user) {
    let params = {
      firstName: user.firstName,
      lastName: user.lastName,
      middleName: user.middleName,
      address: user.address,
      gender: user.gender,
      about: user.about,
      notificationsEnabled: user.notificationsEnabled
    };

    props.callApi('/api/profile/edit/', params).then((res) => {
      props.setUser(user);
    });
  };

  const onChange = (e, field) => {
    e.preventDefault();
    const { value } = e.currentTarget;

    user[field] = value;
  };

  const requestNotifications = (e) => {
    if (!user.notificationsEnabled) {
      bridge.send('VKWebAppAllowNotifications').then(() => {
        user.notificationsEnabled = true;
        props.setUser(user);
      }).catch(() => {
        user.notificationsEnabled = false;
        props.setUser(user);
      });
    } else {
      bridge.send('VKWebAppDenyNotifications').then(() => {
        user.notificationsEnabled = false;
        props.setUser(user);
      });
    }
  }

  return (
    <Panel id={props.id}>
      <PanelHeader
        left={<PanelHeaderButton onClick={props.go} data-to="home">
          {osName === IOS ? <Icon28ChevronBack/> : <Icon24Back/>}
        </PanelHeaderButton>}
      >
        Мой профиль
      </PanelHeader>

      <Group>
        <FormLayout>
          <Input
            top="Фамилия"
            type="text"
            name="lastName"
            defaultValue={user.lastName}
            onChange={(e) => onChange(e, 'lastName')}
          />
          <Input
            top="Имя"
            type="text"
            name="firstName"
            defaultValue={user.firstName}
            onChange={(e) => onChange(e, 'firstName')}
          />
          <Input
            top="Отчество"
            type="text"
            name="middleName"
            defaultValue={user.middleName}
            onChange={(e) => onChange(e, 'middleName')}
          />
          <Select
            defaultValue={user.gender}
            top="Пол"
            bottom="Да, мы знаем про 78 гендеров, но нам для статистики достаточно двух"
            onChange={(e) => onChange(e, 'gender')}
          >
            <option value="0">Предпочитаю не указывать</option>
            <option value="2">Мужской</option>
            <option value="1">Женский</option>
          </Select>
          <Textarea
            top="Адрес"
            type="textarea"
            name="address"
            defaultValue={user.address}
            onChange={(e) => onChange(e, 'address')}
          />
          <FormLayoutGroup>
            <FormStatus header="Если вы принимаете подарок по почте," style={{ color: '#008dd2' }}>
              обязательно укажите полностью фамилию, имя и отчество, а также адрес, включая почтовый индекс
            </FormStatus>
          </FormLayoutGroup>
          <Textarea
            top="Обо мне"
            type="textarea"
            name="about"
            bottom="Тут вы можете указать свои интересы или ссылку на вишлист"
            defaultValue={user.about}
            onChange={(e) => onChange(e, 'about')}
          />
        </FormLayout>
      </Group>

      <Group>
        <Cell
          size="l"
          asideContent={<Switch defaultChecked={user.notificationsEnabled} onClick={requestNotifications}/>}
          description="Мы оповестим, когда подберем вам получателя"
        >
          Получать уведомления
        </Cell>
      </Group>

      <Group>
        <Div>
          <Button size="xl" level="2" onClick={(e) => {saveUser(user);}}>
            Сохранить изменения
          </Button>
        </Div>
      </Group>
      {props.snackbar}
    </Panel>
  );
};

Profile.propTypes = {
  id: PropTypes.string.isRequired,
  go: PropTypes.func.isRequired,
};

export default Profile;
