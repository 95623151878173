import React from 'react';
import PropTypes from 'prop-types';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
import Cell from '@vkontakte/vkui/dist/components/Cell/Cell';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Avatar from '@vkontakte/vkui/dist/components/Avatar/Avatar';
import { FixedLayout } from '@vkontakte/vkui';
import Header from '@vkontakte/vkui/dist/components/Header/Header';

const Home = ({ id, go, fetchedUser, rooms, openRoom, snackbar }) => {
  let activeBlockHeader = null;
  let activeBlock = null;
  let inactiveBlockHeader = null;
  let inactiveBlock = null;

  if (rooms) {
    if (rooms.active.length > 0) {
      activeBlockHeader = <Header>Активные</Header>;
      activeBlock = rooms.active.map((item, key) => {
        let desc = 'Прием заявок до ' + item.sendDate;
        return (<Cell description={desc} onClick={(e) => openRoom(item.id)}>{item.name}</Cell>);
      });
    }

    if (rooms.inactive.length > 0) {
      inactiveBlockHeader = <Header>Завершенные</Header>;
      inactiveBlock = rooms.inactive.map((item, key) => {
        let desc = 'Завершен ' + item.sendDate;
        return (<Cell description={desc} onClick={(e) => openRoom(item.id)}>{item.name}</Cell>);
      });
    }
  }

  return (
    <Panel id={id}>
      <PanelHeader>Мои проекты</PanelHeader>

      {fetchedUser &&
      <Group title="User Data Fetched with VK Bridge">
        <Cell
          before={fetchedUser.avatar ? <Avatar src={fetchedUser.avatar}/> : null}
          description={fetchedUser.city && fetchedUser.city.title ? fetchedUser.city.title : ''}
          onClick={go}
          data-to="profile"
          expandable={true}
        >
          {`${fetchedUser.firstName} ${fetchedUser.lastName}`}
        </Cell>
      </Group>}

      {fetchedUser && <Group title="Rooms">
        {activeBlockHeader}
        {activeBlock}
        {inactiveBlockHeader}
        {inactiveBlock}
        {fetchedUser && !activeBlock && !inactiveBlock &&
        <Div style={{ color: 'gray' }}>Вы пока не участвуете ни в одном проекте. Можете создать свой или присоединиться к существующему</Div>}
      </Group>}

      <FixedLayout vertical="bottom">
        <Div>
          <Button size="xl" level="2" onClick={go} data-to="create">
            Новый проект
          </Button>
        </Div>
      </FixedLayout>
    </Panel>
  );
};

Home.propTypes = {
  id: PropTypes.string.isRequired,
  go: PropTypes.func.isRequired,
  fetchedUser: PropTypes.shape({
    photo_200: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    city: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
};

export default Home;
